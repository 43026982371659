import { Component } from '@angular/core';

@Component({
  selector: 'app-looker-dashboards',
  standalone: true,
  imports: [],
  templateUrl: './looker-dashboards.component.html',
  styleUrl: './looker-dashboards.component.css'
})
export class LookerDashboardsComponent {

}
