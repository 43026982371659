<div class="jumbotron">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h1 class="display-2 text-center">Sequencing all 70,000</h1>
                <h1 class="display-2 text-center">eukaryotic species of</h1>
                <h1 class="display-2 text-center">Britain and Ireland </h1>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1 class="page-header text-center">
                Darwin Tree of Life Data Portal
            </h1>
            <h2>
                The Darwin Tree of Life project aims to sequence the genomes of all 70,000 species of eukaryotic organisms in Britain and Ireland. It is a collaboration between biodiversity, genomics and analysis partners that hopes to transform the way we do biology,
                conservation and biotechnology.
            </h2>
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/data']">
                        <span class="material-icons">table_chart</span>
                        Data Portal
                    </button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/tracking']">
                        <span class="material-icons">linear_scale</span>
                        Status Tracking
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/tree']">
                        <span class="material-icons">timeline</span>
                        Phylogeny
                    </button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/gis']">
                        <span class="material-icons">map</span>
                        Sampling Map
                    </button>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/publications']">
                        <span class="material-icons">book</span>
                        Publications
                    </button>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</div>
