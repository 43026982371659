

<div mat-dialog-content>
    You are about to download {{data.fileCount}} files totalling an approximate size of {{data.fileSize}} as a single zip archive. Continue?
  </div>
<div class="confirmation-dialog-btn" mat-dialog-actions>
  <button  type="button" style="float: right;" class="btn btn-primary" mat-raised-button  (click)="close()">Cancel
  </button>
  <div *ngIf="!data.hideAction;">
    <button
      [mat-dialog-close]="true" style="float: right;" class="btn btn-success" (click)="download()">Download
    </button>
  </div>
</div>
