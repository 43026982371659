<div>
    <ngx-spinner bdColor="rgba(0,0,0,0.9)" [fullScreen]="true" size="large" color="#fff" type="ball-clip-rotate-pulse">
    </ngx-spinner>

    <div class="jumbotron jumbotron-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="padding-top: 20px;">
        <div class="row">
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Article Type" isShowCount="true"></app-filter>
                    </div>
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Journal" isShowCount=true></app-filter>
                    </div>
                    <div class="col-md-12 col-sm-6">
                        <app-filter title="Publication Year" isShowCount="true"></app-filter>
                    </div>
                </div>

            </div>

            <div class="col-md-10" >
                <h1 class="page-header">Publications</h1>
                <br />
                <div class="column-header" >
<!--                    <button *ngIf="filterService.activeFilters.length > 0 || filterService.selectedFilterValue != ''-->
<!--                    && (filterService.AnnotationFilters.length > 0 ||-->
<!--                    filterService.AnnotationCompleteFilters.length > 0 || filterService.AssembliesFilters.length > 0-->
<!--                    || filterService.RawDataFilters.length > 0)" type="button" style="float: right;"-->
<!--                            class="btn btn-primary" mat-raised-button (click)="openDialog()">Download</button>-->

<!--                    <button type="button" style="float: right;" class="btn btn-primary"-->
<!--                            mat-raised-button (click)="downloadCSV()">Download metadata</button>-->
                    <div *ngIf="hasActiveFilters()">
                        <app-active-filter true></app-active-filter>

                        <span *ngIf="(filterService.selectedFilterValue!= undefined &&
                        filterService.selectedFilterValue != '')">
                    <button type="button" class="btn btn-success">{{filterService.selectedFilterValue.taxonomy}}
                        <span class="badge badge-light" (click)="filterService.removeTaxaFilter(
                        filterService.selectedFilterValue.taxonomy)">X</span>
                    </button>
                </span>
                        <button class="btn btn-primary" type = "button" (click)="removeFilter()">
                            Remove all filters</button>

                    </div>
                </div>
<!--                <mat-form-field class="column-header">-->
<!--                    <mat-label>Search</mat-label>-->
<!--                    <input  #searchInput matInput [(ngModel)]='filterService.searchText'-->
<!--                            (ngModelChange)="this.searchUpdate.next($event)" placeholder="Ex. lutra" #input>-->
<!--                </mat-form-field>-->
                <table mat-table  matTableExporter  #table
                       [dataSource]="dataSource" matSort (matSortChange)="customSort($event)">

                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                                         [ngClass]="'w-80 th-left'">Title</mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="'w-80 th-left'">
                            <a href="{{element['_source']['url']}}">{{element['_source']['title']}}</a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="journal_name">
                        <mat-header-cell *matHeaderCellDef [ngClass]="'w-150 th-left'">Journal</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                  [ngClass]="'w-150 th-left'">{{getJournalName(element['_source'])}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="year">
                        <mat-header-cell *matHeaderCellDef
                                         [ngClass]="'w-120 th-center'">Publication Year</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                  [ngClass]="'w-120 th-center'">{{getYear(element['_source'])}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="study_id">
                        <mat-header-cell *matHeaderCellDef [ngClass]="'w-120 th-left'">Study ID</mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="'w-120 th-left'">
                            <a href="https://www.ebi.ac.uk/ena/browser/view/{{element['_source']['study_id']}}">
                                {{element['_source']['study_id']}}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="organism_name">
                        <mat-header-cell *matHeaderCellDef [ngClass]="'w-150 th-left'">Organism Name</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                  [ngClass]="'w-150 th-left'">
                            <a [routerLink]="['/data/root/details/', element['_source']['organism_name']]">
                                {{element['_source']['organism_name']}}
                            </a>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                </table>
                <mat-paginator [pageSizeOptions]="[20, 40, 60, 100]" showFirstLastButtons
                               [length]="dataCount" (page)="pageChanged($event)"></mat-paginator>
            </div>
        </div>
    </div>
</div>
