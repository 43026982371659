<div>
    <ngx-spinner bdColor="rgba(0,0,0,0.9)" [fullScreen]="true" size="large" color="#fff" type="ball-clip-rotate-pulse">
    </ngx-spinner>
    <div class="container" style="padding-top: 65px;">
        <h1 class="text-center page-header">{{bioSampleObj?.organism}}</h1>
        <dl class="row">
            <dt class="col-md-3" *ngIf="bioSampleObj?.commonName">Name:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.commonName">{{bioSampleObj?.commonName}}</dd>

            <dt class="col-md-3">Organism:</dt>
            <dd class="col-md-9">{{bioSampleObj?.organism}}</dd>

            <dt class="col-md-3" *ngIf="checkTolidExists(bioSampleObj)">ToL ID:</dt>
            <dd class="col-md-3" *ngIf="checkTolidExists(bioSampleObj)">{{typeofTol(bioSampleObj.tolid)}}</dd>
            <dd class="col-md-6" *ngIf="checkTolidExists(bioSampleObj)">
                <ng-container *ngIf="checkTolidExists(bioSampleObj)">
                    <a class="no-underline badge badge-pill goat-color2" target="_blank" href="{{generateTolidLink(bioSampleObj)}}">ToL QC</a>
                </ng-container>
            </dd>

            <dt class="col-md-3">INSDC ID:</dt>
            <dd class="col-md-3" *ngIf="INSDC_ID != null">{{INSDC_ID}}</dd>
            <dd class="col-md-3" *ngIf="INSDC_ID == null">-</dd>
            <dd class="col-md-6">
                <!-- <a class="no-underline badge badge-pill goat-color" target="_blank" href="https://goat.genomehubs.org/records?record_id={{bioSampleObj?.taxId}}&result=taxon&taxonomy=ncbi#{{bioSampleObj?.organism}}">GoaT
                info</a> -->
            </dd>

            <dt class="col-md-3" *ngIf="bioSampleObj?.sex">Sex:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.sex">{{bioSampleObj?.sex}}</dd>

            <dt class="col-md-3" *ngIf="bioSampleObj?.currentStatus">Current Status:</dt>
            <dd class="col-md-9" *ngIf="bioSampleObj?.currentStatus">
                <mat-chip [ngStyle]="{'background-color': getStatusStyle(bioSampleObj?.currentStatus)[0]}">
                    <span [ngClass]="getStatusStyle(bioSampleObj?.currentStatus)[1]">{{bioSampleObj?.currentStatus}}</span>
                </mat-chip>
            </dd>
        </dl>


        <mat-tab-group dynamicHeight #tabgroup>
            <mat-tab label="Metadata" *ngIf="bioSampleObj?.records && bioSampleObj?.records.length">
                <!-- Related Organisms -->
                <div class="col-md-12" *ngIf="bioSampleObj?.records.length">
                    <hr>
                    <h1 class="col-md-12 page-header">Related Organisms</h1>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'related-organisms-metadata'})">Download
                        metadata</button>
                    <mat-form-field class="search-bar">
                        <mat-label>Search</mat-label>
                        <input matInput [(ngModel)]='searchText' (keyup)="getSearchResults()" placeholder="Ex. Salmo Trutta" #input>
                    </mat-form-field>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="row">
                                <div class="col-md-12 col-md-6" *ngIf="sexFilters.length">
                                    <div class="card bg-lite mb-3 filter-top">
                                        <h3 class="card-header filter-heading">
                                            <span #lbltextSex>Sex</span>
                                            <i class="material-icons float-right sex-inactive" *ngIf="sexFilters.length > 3 && isSexFilterCollapsed" (click)="toggleCollapse(lbltextSex.innerText)">add</i>
                                            <i class="material-icons float-right sex-inactive" *ngIf="sexFilters.length > 3 && !isSexFilterCollapsed" (click)="toggleCollapse(lbltextSex.innerText)">remove</i>
                                        </h3>
                                        <ul id="sex-inactive" class="list-group" [ngClass]="{'long-list': itemLimitSexFilter > filterSize}">
                                            <ng-container *ngFor="let filter of sexFilters; let i = index">
                                                <li id="{{i}}" class="list-group-item d-flex justify-content-between align-items-center sex-inactive" [ngClass]="checkFilterIsActive(filter.key)" (click)="onFilterClick($event, lbltextSex.innerText, filter.key)" style="cursor: pointer; padding: .75rem 0.25rem;"
                                                    *ngIf="i<itemLimitSexFilter && filter.key != ''">
                                                    {{filter.key}}
                                                    <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-12 col-md-6" *ngIf="organismPartFilters.length">
                                    <div class="card bg-lite mb-3 filter-top">
                                        <h3 class="card-header filter-heading">
                                            <span #lbltextOrgPart>Organism Part</span>
                                            <i class="material-icons float-right org-part-inactive" *ngIf="organismPartFilters.length > 3 && isOrganismPartCollapsed" (click)="toggleCollapse(lbltextOrgPart.innerText)">add</i>
                                            <i class="material-icons float-right org-part-inactive" *ngIf="organismPartFilters.length > 3 && !isOrganismPartCollapsed" (click)="toggleCollapse(lbltextOrgPart.innerText)">remove</i>
                                        </h3>
                                        <ul id="org-part-inactive" class="list-group" [ngClass]="{'long-list': itemLimitOrgFilter > filterSize}">
                                            <ng-container *ngFor="let filter of organismPartFilters; let i = index">
                                                <li id="{{i}}" class="list-group-item d-flex justify-content-between align-items-center org-part-inactive" [ngClass]="checkFilterIsActive(filter.key)" (click)="onFilterClick($event, lbltextOrgPart.innerText, filter.key)" style="cursor: pointer; padding: .75rem 0.25rem;"
                                                    *ngIf="i<itemLimitOrgFilter && filter.key != ''">
                                                    {{filter.key}}
                                                    <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-12 col-md-6" *ngIf="trackingSystemFilters.length">
                                    <div class="card bg-lite mb-3 filter-top">
                                        <h3 class="card-header filter-heading">
                                            <span #lbltextTrack>Tracking Status</span>
                                            <i class="material-icons float-right tracking-status-inactive" *ngIf="trackingSystemFilters.length > 3 && isTrackCollapsed" (click)="toggleCollapse(lbltextTrack.innerText)">add</i>
                                            <i class="material-icons float-right tracking-status-inactive" *ngIf="trackingSystemFilters.length > 3 && !isTrackCollapsed" (click)="toggleCollapse(lbltextTrack.innerText)">remove</i>
                                        </h3>
                                        <ul id="tracking-status-inactive" class="list-group" [ngClass]="{'long-list': itemLimitTrackFilter > filterSize}">
                                            <ng-container *ngFor="let filter of trackingSystemFilters; let i = index">
                                                <li id="i" class="list-group-item d-flex justify-content-between align-items-center tracking-status-inactive" [ngClass]="checkFilterIsActive(filter.key)" (click)="onFilterClick($event, lbltextTrack.innerText, filter.key)" style="cursor: pointer; padding: .75rem 0.25rem;"
                                                    *ngIf="i<itemLimitTrackFilter && filter.key != ''">
                                                    {{filter.key}}
                                                    <span class="badge badge-pill badge-info">{{filter.doc_count}}</span>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <h1 *ngIf="activeFilters.length !== 0">Filters:
                                    <button type="button" class="btn btn-success" *ngFor="let filter of activeFilters">
                                        {{filter}}
                                        <span class="badge badge-light" (click)="removeFilter(filter)">X</span>
                                    </button>
                                    <button type="button" class="btn btn-primary" (click)="removeAllFilters()">
                                        Remove all filters
                                    </button>
                                </h1>
                                <table matTableExporter  mat-table [dataSource]="dataSourceRecords" matSort style="width: 100%;" class="relatedOrganismsTable">
                                    <ng-container matColumnDef="accession">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a [routerLink]="['/data/organism/details/', element.accession]">
                                                {{element.accession}}</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="organism">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            {{element.organism}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="commonName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="sex">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="organismPart">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="trackingSystem">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-chip [ngStyle]="{'background-color': getStatusStyle(element.trackingSystem)[0]}">
                                                <span [ngClass]="getStatusStyle(element.trackingSystem)[1]">{{element.trackingSystem}}</span>
                                            </mat-chip>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                                </table>
                                <mat-paginator #relatedOrganisms style="width: 100%;" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="specBioSampleTotalCount"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Data" *ngIf="dataSourceRelatedAnnotationCount != 0 || dataSourceAnnotationCount != 0 || dataSourceAssembliesCount != 0 || dataSourceFilesCount != 0">
                <!-- Related Annotation Section -->
                <div class="col-md-12" *ngIf="dataSourceRelatedAnnotationCount != 0">
                    <hr>
                    <h1 class="col-md-12 page-header">Related Annotation</h1>
                    <mat-expansion-panel (opened)="expanded()">
                        <mat-expansion-panel-header class="page-header">
                            Column Selection
                        </mat-expansion-panel-header>
                        <div class="col-md-12">
                            <div class="row">
                                <mat-checkbox *ngFor="let data of displayedColumnsRelatedAnnotation" class="col-md-4" color="primary" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" [checked]="data.selected" (change)="showSelectedAnnotationsColumn(data, $event.checked)">
                                    {{data.name}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <br />
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'related-annotation-metadata'})">Download
                        metadata</button>
                    <mat-form-field class="search-bar">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="relatedAnnotationSearch($event)" #input>
                    </mat-form-field>
                    <table mat-table matTableExporter  [dataSource]="dataSourceRelatedAnnotation" matSort style="width: 100%;">
                        <ng-container matColumnDef="study_accession">
                            <mat-header-cell *matHeaderCellDef> Study Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.study_accession}}">{{element.study_accession}}</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="sample_accession">
                            <mat-header-cell *matHeaderCellDef> Sample Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.sample_accession}}">{{element.sample_accession}}</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="secondary_sample_accession">
                            <mat-header-cell *matHeaderCellDef> Secondary Sample Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.secondary_sample_accession}}">{{element.secondary_sample_accession}}</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="analysis_accession">
                            <mat-header-cell *matHeaderCellDef> Analysis Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.analysis_accession}}">{{element.analysis_accession}}</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="analysis_title">
                            <mat-header-cell *matHeaderCellDef> Analysis Title </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.analysis_title}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="analysis_type">
                            <mat-header-cell *matHeaderCellDef> Analysis Type </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.analysis_type}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="assembly_type">
                            <mat-header-cell *matHeaderCellDef> Assembly Type </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.assembly_type}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="center_name">
                            <mat-header-cell *matHeaderCellDef> Center Name </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.center_name}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="first_public">
                            <mat-header-cell *matHeaderCellDef> First Public </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.first_public}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="last_updated">
                            <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.last_updated}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="study_title">
                            <mat-header-cell *matHeaderCellDef> Study Title </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.study_title}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tax_id">
                            <mat-header-cell *matHeaderCellDef> Tax ID </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{tax_id}}">{{tax_id}}</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="study_alias">
                            <mat-header-cell *matHeaderCellDef> Study Alias </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.study_alias}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="analysis_alias">
                            <mat-header-cell *matHeaderCellDef> Analysis Alias </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.analysis_alias}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="generated_bytes">
                            <mat-header-cell *matHeaderCellDef> Generated files: Size </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.generated_bytes}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="generated_md5">
                            <mat-header-cell *matHeaderCellDef> Generated files: MD5 </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.generated_md5}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="generated_ftp">
                            <mat-header-cell *matHeaderCellDef> Generated files: FTP </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.generated_ftp}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="generated_aspera">
                            <mat-header-cell *matHeaderCellDef> Generated files: Aspera </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.generated_aspera}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="generated_galaxy">
                            <mat-header-cell *matHeaderCellDef> Generated files: Galaxy </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.generated_galaxy}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="submitted_bytes">
                            <mat-header-cell *matHeaderCellDef> Submitted files: Size </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.submitted_bytes}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="submitted_md5">
                            <mat-header-cell *matHeaderCellDef> Submitted files: MD5 </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.submitted_md5}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="submitted_ftp">
                            <mat-header-cell *matHeaderCellDef> Submitted files: FTP </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.submitted_ftp}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="submitted_aspera">
                            <mat-header-cell *matHeaderCellDef> Submitted files: Aspera </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.submitted_aspera}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="broker_name">
                            <mat-header-cell *matHeaderCellDef> Broker Name </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.broker_name}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="sample_alias">
                            <mat-header-cell *matHeaderCellDef> Sample Alias </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.sample_alias}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="sample_title">
                            <mat-header-cell *matHeaderCellDef> Sample Title </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="txt-center">
                                {{element.sample_title}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsAnnotations"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsAnnotations;"></mat-row>
                    </table>
                    <mat-paginator #relatedAnnotationTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceRelatedAnnotationCount" style="width: 100%;"></mat-paginator>
                </div>

                <!-- Annotation Section -->
                <div class="col-md-12" *ngIf="dataSourceAnnotationCount != 0">
                    <hr>
                    <h1 class="col-md-12 page-header">Annotation</h1>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'annotation-metadata'})">Download
                        metadata</button>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-success" mat-raised-button (click)="downloadAnnotation()">Download all Annotations</button>
                    <mat-form-field class="search-bar">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="annotationSearch($event)" #input>
                    </mat-form-field>
                    <table mat-table matTableExporter  [dataSource]="dataSourceAnnotation" matSort style="width: 100%;" class="annotationTable">
                        <ng-container matColumnDef="accession">
                            <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.accession}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="annotation">
                            <mat-header-cell *matHeaderCellDef> Annotation </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span>
                                    <a class="no-underline" target="_blank" href="{{element.annotation.GTF}}">GTF</a>,
                                </span>
                                <span>
                                    <a class="no-underline" target="_blank" href="{{element.annotation.GFF3}}">GFF3</a>
                                </span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="proteins">
                            <mat-header-cell *matHeaderCellDef> Proteins </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="{{element.proteins.FASTA}}">FASTA</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="transcripts">
                            <mat-header-cell *matHeaderCellDef> Transcripts </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="{{element.transcripts.FASTA}}">FASTA</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="softmasked_genome">
                            <mat-header-cell *matHeaderCellDef> Softmasked genome </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="{{element.softmasked_genome.FASTA}}">FASTA</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="other_data">
                            <mat-header-cell *matHeaderCellDef> Other data </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="{{element.other_data.ftp_dumps}}">FTP
                                    dumps</a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="view_in_browser">
                            <mat-header-cell *matHeaderCellDef> View in browser </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="{{element.view_in_browser}}">ensembl.org</a>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsAnnotation"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsAnnotation;"></mat-row>
                    </table>
                    <mat-paginator #annotationTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceAnnotationCount" style="width: 100%;"></mat-paginator>
                </div>

                <!-- Assemblies Section -->
                <div class="col-md-12" *ngIf="dataSourceAssembliesCount != 0">
                    <hr>
                    <h1 class="col-md-12 page-header">Related assemblies</h1>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-primary" mat-raised-button (click)="exporter.exportTable('csv', {fileName:'assemblies-metadata'})">Download
                        metadata</button>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;" class="btn btn-success" mat-raised-button (click)="downloadAssemblies()">Download all Assemblies</button>
                    <mat-form-field class="search-bar">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="assembliesSearch($event)" placeholder="Ex. ILLUMINA" #input>
                    </mat-form-field>
                    <table mat-table matTableExporter  [dataSource]="dataSourceAssemblies" matSort style="width: 100%;" class="relatedAssembliesTable">
                        <ng-container matColumnDef="accession">
                            <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.accession}}">{{element.accession}}</a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="assembly_name">
                            <mat-header-cell *matHeaderCellDef> Assembly Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.assembly_name}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="version">
                            <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.version}} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsAssemblies"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsAssemblies;"></mat-row>
                    </table>
                    <mat-paginator #assembliesTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceAssembliesCount" style="width: 100%;"></mat-paginator>
                </div>

                <!-- Experiment Files Section -->
                <div class="col-md-12" *ngIf="dataSourceFilesCount != 0">
                    <hr>
                    <h1 class="col-md-12 page-header">Related files</h1>
                    <mat-expansion-panel (opened)="expanded()">
                        <mat-expansion-panel-header class="page-header">
                            Column Selection
                        </mat-expansion-panel-header>
                        <div class="col-md-12">
                            <div class="row">
                                <mat-checkbox *ngFor="let data of experimentColumnsDefination" class="col-md-4" color="primary" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" [checked]="data.selected" (change)="showSelectedColumn(data, $event.checked)">
                                    {{data.column}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <br />
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;"
                            class="btn btn-primary" mat-raised-button
                            (click)="exporter.exportTable('csv', {fileName:'experiments-metadata'})">
                        Download metadata</button>
                    <button type="button" style="float: right; background-color: #4BBEFD; color: #ffffff;"
                            class="btn btn-success" mat-raised-button (click)="downloadRawFiles()">
                        Download all FASTQ files</button>
                    <mat-form-field class="search-bar">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="filesSearch($event)" placeholder="Ex. ILLUMINA" #input>
                    </mat-form-field>
                    <div class="table-container">
                        <table mat-table matTableExporter  [dataSource]="dataSourceFiles" class="relatedFilesTable">
                            <ng-container matColumnDef="study_accession">
                                <mat-header-cell *matHeaderCellDef> Study Accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.study_accession}}">{{element.study_accession}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sample_accession">
                                <mat-header-cell *matHeaderCellDef> Sample Accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.sample_accession}}">{{element.sample_accession}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="experiment_accession">
                                <mat-header-cell *matHeaderCellDef> Experiment Accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.experiment_accession}}">{{element.experiment_accession}}</a>
                                </mat-cell>
                            </ng-container>
                            <!-- Third Column -->
                            <ng-container matColumnDef="run_accession">
                                <mat-header-cell *matHeaderCellDef> Run Accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.run_accession}}">{{element.run_accession}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="tax_id">
                                <mat-header-cell *matHeaderCellDef> Tax Id </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/Taxon:{{element.tax_id}}">{{element.tax_id}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="scientific_name">
                                <mat-header-cell *matHeaderCellDef> Scientific Name </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.scientific_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fastq_ftp">
                                <mat-header-cell *matHeaderCellDef> FASTQ FTP </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    <ul class="list-group" style="list-style-type:none;">
                                        <li *ngFor="let fastq of element.fastq_ftp">
                                            <a href="ftp://{{fastq}}">{{element.run_accession}}</a>
                                        </li>
                                    </ul>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_ftp">
                                <mat-header-cell *matHeaderCellDef> Submitted FTP </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    <ul class="list-group" style="list-style-type:none;">
                                        <li *ngFor="let submitted_ftp of element.submitted_ftp">
                                            <a href="ftp://{{submitted_ftp}}">{{element.study_accession}}</a>
                                        </li>
                                    </ul>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sra_ftp">
                                <mat-header-cell *matHeaderCellDef> SRA FTP </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    <ul class="list-group" style="list-style-type:none;">
                                        <li *ngFor="let sra_ftp of element.sra_ftp">
                                            <a href="ftp://{{sra_ftp}}">{{element.sra_ftp}}</a>
                                        </li>
                                    </ul>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="base_count">
                                <mat-header-cell *matHeaderCellDef> Base count </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.base_count}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="broker_name">
                                <mat-header-cell *matHeaderCellDef> Broker name </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.broker_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="center_name">
                                <mat-header-cell *matHeaderCellDef> Center name </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.center_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cram_index_aspera">
                                <mat-header-cell *matHeaderCellDef> Cram index aspera </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.cram_index_aspera}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cram_index_galaxy">
                                <mat-header-cell *matHeaderCellDef> Cram index galaxy </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.cram_index_galaxy}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cram_index_ftp">
                                <mat-header-cell *matHeaderCellDef> Cram index FTP </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.cram_index_ftp}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="experiment_alias">
                                <mat-header-cell *matHeaderCellDef> Experiment alias </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.experiment_alias}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="experiment_title">
                                <mat-header-cell *matHeaderCellDef> Experiment title </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.experiment_title}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fastq_aspera">
                                <mat-header-cell *matHeaderCellDef> Fastq aspera </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_aspera}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fastq_bytes">
                                <mat-header-cell *matHeaderCellDef> Fastq bytes </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_bytes}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fastq_galaxy">
                                <mat-header-cell *matHeaderCellDef> Fastq galaxy </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.fastq_galaxy}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fastq_md5">
                                <mat-header-cell *matHeaderCellDef> Fastq md5 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.fastq_md5}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="first_created">
                                <mat-header-cell *matHeaderCellDef> First created </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.first_created}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="first_public">
                                <mat-header-cell *matHeaderCellDef> First public </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.first_public}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="instrument_platform">
                                <mat-header-cell *matHeaderCellDef> Instrument Platform </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.instrument_platform}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="instrument_model">
                                <mat-header-cell *matHeaderCellDef> Instrument Model </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.instrument_model}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="last_updated">
                                <mat-header-cell *matHeaderCellDef> Last updated </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.last_updated}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_layout">
                                <mat-header-cell *matHeaderCellDef> Library Layout </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_layout}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_name">
                                <mat-header-cell *matHeaderCellDef> Library name </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_strategy">
                                <mat-header-cell *matHeaderCellDef> Library Strategy </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.library_strategy}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_source">
                                <mat-header-cell *matHeaderCellDef> Library Source </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.library_source}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_selection">
                                <mat-header-cell *matHeaderCellDef> Library Selection </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.library_selection}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="library_construction_protocol">
                                <mat-header-cell *matHeaderCellDef> Library Construction Protocol </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.library_construction_protocol}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="nominal_length">
                                <mat-header-cell *matHeaderCellDef> Nominal length </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.nominal_length}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="nominal_sdev">
                                <mat-header-cell *matHeaderCellDef> Nominal sdev </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.nominal_sdev}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="read_count">
                                <mat-header-cell *matHeaderCellDef> Read count </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.read_count}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="run_alias">
                                <mat-header-cell *matHeaderCellDef> Run alias </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.run_alias}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sample_alias">
                                <mat-header-cell *matHeaderCellDef> Sample alias </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sample_alias}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sample_title">
                                <mat-header-cell *matHeaderCellDef> Sample title </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.sample_title}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="secondary_sample_accession">
                                <mat-header-cell *matHeaderCellDef> Secondary sample accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.secondary_sample_accession}}">{{element.secondary_sample_accession}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="secondary_study_accession">
                                <mat-header-cell *matHeaderCellDef> Secondary study accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.secondary_study_accession}}">{{element.secondary_study_accession}}</a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sra_aspera">
                                <mat-header-cell *matHeaderCellDef> SRA aspera </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_aspera}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sra_bytes">
                                <mat-header-cell *matHeaderCellDef> SRA bytes </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_bytes}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sra_galaxy">
                                <mat-header-cell *matHeaderCellDef> SRA galaxy </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_galaxy}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sra_md5">
                                <mat-header-cell *matHeaderCellDef> SRA md5 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.sra_md5}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="study_alias">
                                <mat-header-cell *matHeaderCellDef> Study alias </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.study_alias}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="study_title">
                                <mat-header-cell *matHeaderCellDef> Study title </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.study_title}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="submission_accession">
                                <mat-header-cell *matHeaderCellDef> Submission accession </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.submission_accession}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_aspera">
                                <mat-header-cell *matHeaderCellDef> Submitted aspera </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.submitted_aspera}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_bytes">
                                <mat-header-cell *matHeaderCellDef> Submitted bytes </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font"> {{element.submitted_bytes}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_format">
                                <mat-header-cell *matHeaderCellDef> Submitted format </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.submitted_format}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_galaxy">
                                <mat-header-cell *matHeaderCellDef> Submitted galaxy </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.submitted_galaxy}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="submitted_md5">
                                <mat-header-cell *matHeaderCellDef> Submitted md5 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="assemblies-font">
                                    {{element.submitted_md5}} </mat-cell>
                            </ng-container>


                            <mat-header-row *matHeaderRowDef="displayedColumnsFiles"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsFiles;"></mat-row>
                        </table>
                    </div>
                    <mat-paginator #experimentsTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceFilesCount"></mat-paginator>
                </div>
            </mat-tab>

            <mat-tab label="Symbionts" *ngIf="bioSampleObj?.symbionts_records && bioSampleObj?.symbionts_records.length">
                <div class="col-md-12" *ngIf="bioSampleObj?.symbionts_records.length">
                    <hr>
                    <h1 class="col-md-12 page-header">Related Symbionts</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <mat-form-field class="search-bar">
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="symbiontsRecordsSearch($event)" #input>
                                </mat-form-field>
                                <table matTableExporter  mat-table [dataSource]="dataSourceSymbiontsRecords" matSort style="width: 100%;">
                                    <ng-container matColumnDef="accession">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> BioSample ID
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a [routerLink]="['/data/organism/details/', element.accession]">
                                                {{element.accession}}</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="organism">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Organism </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            {{element.organism}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="commonName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element?.commonName}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="sex">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Sex </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.sex}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="organismPart">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Organism part
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.organismPart}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="trackingSystem">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-chip [ngStyle]="{'background-color': getStatusStyle(element.trackingSystem)[0]}">
                                                <span [ngClass]="getStatusStyle(element.trackingSystem)[1]">{{element.trackingSystem}}</span>
                                            </mat-chip>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                                </table>
                                <mat-paginator #relatedOrganisms style="width: 100%;" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="specSymbiontsTotalCount"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="dataSourceSymbiontsAssembliesCount != 0">
                    <hr>
                    <h1 class="col-md-12 page-header">Related assemblies</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <mat-form-field class="search-bar">
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="symbiontsAssembliesSearch($event)" #input>
                                </mat-form-field>
                                <table mat-table matTableExporter [dataSource]="dataSourceSymbiontsAssemblies" matSort style="width: 100%;">
                                    <ng-container matColumnDef="accession">
                                        <mat-header-cell *matHeaderCellDef> Accession </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center">
                                            <a class="no-underline" target="_blank" href="https://www.ebi.ac.uk/ena/browser/view/{{element.accession}}">{{element.accession}}</a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="assembly_name">
                                        <mat-header-cell *matHeaderCellDef> Assembly Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.assembly_name}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="description">
                                        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.description}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="version">
                                        <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="txt-center"> {{element.version}} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsAssemblies"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsAssemblies;"></mat-row>
                                </table>
                                <mat-paginator #assembliesTable [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons [length]="dataSourceSymbiontsAssembliesCount" style="width: 100%;"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Publications" *ngIf="bioSampleObj?.genome_notes && bioSampleObj?.genome_notes.length">
                <!-- Genome Notes Section -->
                <div class="goat-box" *ngIf="bioSampleObj?.genome_notes">
                    <hr>
                    <mat-expansion-panel [expanded]="true" *ngIf="bioSampleObj?.genome_notes?.length">
                        <mat-expansion-panel-header class="page-header">
                            <h1>Published Genome Note</h1>
                        </mat-expansion-panel-header>
                        <div *ngFor="let gen of bioSampleObj?.genome_notes">
                            <div class="card figpanel">
                                <div class="row">
                                    <h1 class="page-header">{{gen.title}}</h1>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <img class="card-img-left" src="{{gen.figureURI}}" style="width: 150px;float: left;">
                                    </div>
                                    <div class="col-md-10">
                                        <!-- <h1>Abstract</h1> -->
                                        <p>{{gen.abstract}}</p>
                                        <a href="{{gen.url}}" class="btn btn-success" target="_blank">Read the Genome
                                            Note
                                            in
                                            full
                                            here</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h5 class="card-title rounded mx-auto d-block">{{gen.caption}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h5 class="card-title rounded mx-auto d-block">{{gen.citeURL}}</h5>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-tab>
            <mat-tab label="Genome Stats (GoAT)" *ngIf="bioSampleObj?.goat_info && (bioSampleObj?.goat_info | json) != '{}'">
                <!-- Goat Info Section -->
                <div class="tab-box" *ngIf="bioSampleObj?.goat_info">
                    <hr>
                    <mat-expansion-panel [expanded]="true" *ngIf="bioSampleObj?.goat_info?.attributes.length">
                        <mat-expansion-panel-header class="page-header">
                            <h1>Goat Info</h1>
                        </mat-expansion-panel-header>
                        <div class="figpanel">
                            <div class="row">
                                <table mat-table matTableExporter  [dataSource]="dataSourceGoatInfo" matSort style="width: 100%;" class="genomeStatsTable">
                                    <ng-container matColumnDef="name">
                                        <mat-header-cell *matHeaderCellDef> Attribute </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.name}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="value">
                                        <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.value}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="count">
                                        <mat-header-cell *matHeaderCellDef> Count </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.count}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="aggregation_method">
                                        <mat-header-cell *matHeaderCellDef> Summary </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{element.aggregation_method}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="aggregation_source">
                                        <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{element.aggregation_source}}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumnsGoatInfo"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsGoatInfo;"></mat-row>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <a href="{{bioSampleObj?.goat_info.url}}" class="btn btn-success" target="_blank">Read the
                                Goat
                                Info in full here</a>
                        </div>
                    </mat-expansion-panel>
                    <hr>
                </div>
            </mat-tab>
            <mat-tab label="Geo Location Maps">
                <ng-template matTabContent>
                    <mat-tab-group>
                        <mat-tab label="Sampling Map" *ngIf="geoLocation">
                            <!-- Geo Section -->
                            <hr>
                            <div class="col-md-12" *ngIf="geoLocation">
                                <app-mapcluster [orgGeoList]="orgGeoList" [specGeoList]="specGeoList"></app-mapcluster>
                            </div>
                            <hr>
                        </mat-tab>
                        <mat-tab label="Occurrences Map" *ngIf="bioSampleObj?.nbnatlas">
                            <hr>
                            <a style="margin-left: 330px;" href="{{nbnatlasMapUrl}}" class="btn btn-success" target="_blank">Open interactive map in new window</a>

                            <div class="col-md-12" style=" display: block; margin-left: 100px; margin-right: auto;">
                                <div [ngStyle]="{height:height+'px',width:width+'px'}" *ngIf="isLoading" class="loading" >
                                    <img style="margin-left: 280px;" [src]="loader" />
                                </div>
                                <div  *ngIf="url">
                                    <br>
                                    <img  [src]='url'
                                          (load)="hideLoader()">
                                    <br>

                                    The National Biodiversity Network records are shown on the map above (See <a href="https://nbnatlas.org/help/nbn-atlas-terms-use/" target="_blank">terms and conditions</a>) .
                                    <a href="https://nbnatlas.org/" target="_blank"><img src="assets/NBNPower.gif"></a>

                                    <br>


                                </div>
                            </div>
                            <hr>
                        </mat-tab>
                    </mat-tab-group>
                </ng-template>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>
