<div class="container" style="padding-top: 65px;">
    <h1 class="text-center page-header">{{bioSampleId}}</h1>
    <dl class="row">

        <dt class="col-sm-3">BioSample ID:</dt>
        <dd class="col-sm-9"><a href="http://www.ebi.ac.uk/biosamples/samples/{{bioSampleObj?.accession}}" target="_blank">{{bioSampleObj?.accession}}</a></dd>

        <dt class="col-sm-3">Tracking Status:</dt>
        <dd class="col-sm-9">{{bioSampleObj?.tracking_status}}</dd>

        <dt class="col-sm-3">Organism:</dt>
        <dd class="col-sm-9">{{bioSampleObj?.organism}}</dd>

        <dt class="col-sm-3">Tax ID:</dt>
        <dd class="col-sm-9">{{bioSampleObj?.taxId}}</dd>

        <!-- Custom Fields Section -->
        <div class="col-sm-12">
            <hr>
            <div class="row" *ngFor="let sample of bioSampleObj?.customFields">
                <dt class="col-sm-3">{{sample?.name}}</dt>
                <dd class="col-sm-9">
                    <a *ngIf="sample.ontologyTerms?.length > 0; else simple" href="{{sample.ontologyTerms[0]}}">{{sample?.value}}</a>
                    <ng-template #simple>
                        {{sample?.value}}
                    </ng-template>
                </dd>
            </div>
        </div>

        <div class="col-sm-12" *ngIf="bioSampleObj?.relationships?.length">
            <hr>
            <h1 class="col-sm-12 page-header">Relationships</h1>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <table mat-table matTableExporter  [dataSource]="dataSourceRecords" matSort style="width: 100%;">
                            <ng-container matColumnDef="accession_source">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="txt-center">
                                    <a routerLink="/data/organism/details/{{element.source}}" target="_blank">
                                        {{element.source}}
                                    </a>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Relationships Type </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="txt-center">
                                    {{element.type}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="accession_target">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Target </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="txt-center">
                                    <a routerLink="/data/organism/details/{{element.target}}" target="_blank">
                                        {{element.target}}
                                    </a>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="specDisplayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: specDisplayedColumns;"></mat-row>
                        </table>
                        <mat-paginator style="width: 100%;" [pageSizeOptions]="[20, 30, 50]" showFirstLastButtons [length]="bioSampleTotalCount"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </dl>

    <hr>
    <div class="row" *ngIf="bioSampleObj?.images">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <app-image-slider
                    [indicatorsVisible]="true"
                    [animationSpeed]="400"
                    [slides]="slides"
                    [autoPlay]="false"
                    [autoPlaySpeed]="5000"
            ></app-image-slider>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
