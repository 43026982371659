<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h1 class="display-2 text-center">Sequencing all 70,000</h1>
                <h1 class="display-2 text-center">eukaryotic species of</h1>
                <h1 class="display-2 text-center">Britain and Ireland </h1>
            </div>
        </div>
    </div>
</div>

<div class="container" style="padding-top: 20px;">
    <div class="row">
        <div class="col-md-12">
            <h1 class="page-header text-center">Contact us</h1>
        </div>
    </div>

    <div class="text-center">
        <p class="mb64">To contact us about any aspect of the Darwin Tree of Life programme please use one of the following methods.<br>
            <br>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">
                <p class="mb0"><strong>Phone :</strong></p>
                <p class="mb32"><a href="tel:+44 (0)1223 496925">+44 (0)1223 496925</a></p>
                <p class="mb0"><strong>Email :</strong></p>
                <p class="mb32"><a href="mailto:contact@darwintreeoflife.org">contact&#64;darwintreeoflife.org</a></p>
                <p class="mb0"><strong>DToL data coordination centre helpdesk :</strong></p>
                <p class="mb32"><a href="mailto:dtol-dcc@ebi.ac.uk">dtol-dcc&#64;ebi.ac.uk</a></p>
                <p class="mb0"><strong>Address :</strong></p>
                <p class="mb32"> Darwin Tree of Life<br> Wellcome Sanger Institute<br> Wellcome Genome Campus<br> Hinxton
                    <br> Cambridgeshire
                    <br> CB10 1SA </p>
            </div>
        </div>
    </div>
</div>