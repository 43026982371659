<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h1 class="display-2 text-center">Sequencing all 70,000</h1>
                <h1 class="display-2 text-center">eukaryotic species of</h1>
                <h1 class="display-2 text-center">Britain and Ireland </h1>
            </div>
        </div>
    </div>
  </div>

<div class="container" style="padding-top: 20px;">
    <div class="row">
        <div class="col-md-12">
            <h1 class="page-header text-center">About the Darwin Tree of Life Project</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="card">
                <img class="card-img-top" src="../../assets/DSC06308.JPG" alt="Card image cap">
                <div class="card-body">
                    <h2 class="card-title page-header">What we do</h2>
                    <p class="card-text">We use genomic data to understand the evolution of the diversity of life,
                        to explore the biology of organisms and ecosystems, to aid conservation efforts and to provide
                        new tools for medicine and biotechnology. The Darwin Tree of Life Project is one of several
                        initiatives across the globe working towards the ultimate goal of sequencing all complex life
                        on Earth, in a venture known as the Earth BioGenome Project. We are focussing on the organisms
                        that live in and around Britain and Ireland because they constitute what is probably the best
                        known and most deeply studied biota in the world, explored during centuries of observation and
                        research.</p>
                    <a href="https://www.darwintreeoflife.org/" class="btn btn-primary">www.darwintreeoflife.org</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <img class="card-img-top img-left" src="../../assets/IMG_4070.JPG" alt="Card image cap">
                <div class="card-body">
                    <h2 class="card-title page-header">Our Approach</h2>
                    <p class="card-text">All life is linked through the common thread of the DNA sequence of their
                        genomes. The Darwin Tree of Life project will carefully collect representatives of each
                        species, use advanced DNA sequencing technologies to generate high quality genome sequences,
                        and cutting edge computational tools to understand how the DNA sequence translates into the
                        diversity of life. We will sequence animals, plants, fungi and protists, and release the data
                        openly for all to use.</p>
                    <p class="card-text">
                        In our first, two-year phase we will sequence the genomes of 2000 species to build the skills
                        needed to complete our task, to generate reference genomes for about one third of the families of
                        organisms present in Britain and Ireland, and to prove the utility of these genomes for research
                        and applied goals.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
